<template>
    <div></div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    beforeCreate() {},
    beforeDestroy() {},
    mounted() {
        // var url =
        //     'https://ds.alipay.com/?scheme=alipays%3A%2F%2Fplatformapi%2Fstartapp%3FappId%3D2021003173692931%26page%3Dpages%2Fclinic-payment%2Forder%2Fcreate%26query%3DhisTraceNo%253D4523579%2526patientId%253D920727%2526hospitalId%253D34';
        // window.location.href = url;
        this.getBizInfo();
    },
    methods: {
        getBizInfo() {
            // 'dc5db1145e184afb8e7ed3889488cb54'
            console.log(this.$route.params);
            var urlRequestId = this.$route.query.urlRequestId;
            this.$axios
                .get(`/pay/h5/url/param/get?urlRequestId=${urlRequestId}`)
                .then(res => {
                    this._navigator(res);
                })
                .catch(err => {
                    console.log('err', err);
                    if (err.response.status == 521) {
                        this.showErr = true;
                        this.errMsg = err.response.data.msg || '';
                    }

                    Toast.clear();
                });
        },
        _navigator(res) {
            var appid = this.getAppid();
            var query = this.getQuery(res.data);
            var page = this.getPage(res.data.businessCode) + '?' + query;

            var miniPage = `alipays://platformapi/startapp?appId=${appid}&page=${page}`;
            var url = `https://ds.alipay.com/?scheme=` + encodeURIComponent(miniPage);
            console.log(url, page);
            debugger;
            window.location.href = url;
        },

        getAppid() {
            if (process.env.NODE_ENV === 'production') {
                return '2021003174630433';
            } else {
                return '2021003173692931';
            }
        },

        getPage(businessCode) {
            if (businessCode == 'ClinicRecord') {
                return 'pages/orders/clinic-order/other-channel-create';
            } else if (businessCode == 'subsequentVisitMedicine') {
                return 'pages/orders/premedication-order/create';
            }
        },

        getQuery(data) {
            if (data.businessCode == 'ClinicRecord') {
                var query = `hisTraceNo=${data.channelOrderId}&patientId=${data.patientId}&hospitalId=${data.hospitalId}&userId=${data.userId}`;
                return encodeURIComponent(query);
            } else if (data.businessCode == 'subsequentVisitMedicine') {
                var query = `recipeId=${data.recipeId}&patientId=${data.patientId}&hospitalId=${data.hospitalId}&userId=${data.userId}&orderId=${data.orderId}`;
                return encodeURIComponent(query);
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
